<template>
  <v-dialog
    v-model="dialog"
    width="1100"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        Detalle de destinatarios
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-2">
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="6" md="3" class="pt-3 pb-0 pr-sm-3 pr-0 pl-0">
            <v-data-table
              class="elevation-2"
              sort-by="canal_nombre"
              :headers="[{ text: 'Canales', value: 'canal_nombre', align: 'center', sortable: false }]"
              :items="canales"
              :loading="load"
              :items-per-page="-1"
              hide-default-header
              hide-default-footer
              dense
            >
              <template v-slot:top>
                <div class="d-flex justify-center py-1 body-1 font-weight-bold">
                  Canales
                </div>
                <v-divider></v-divider>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto my-2"
                  max-width="400"
                  type="info"
                  dense
                  text
                >
                  Habilitado para todos los canales
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="pt-3 pb-0 px-0">
            <v-data-table
              class="elevation-2"
              sort-by="sucursal_nombre"
              :headers="[{ text: 'Sucursales', value: 'sucursal_nombre', align: 'center', sortable: false }]"
              :items="sucursales"
              :loading="load"
              :items-per-page="-1"
              hide-default-header
              hide-default-footer
              dense
            >
              <template v-slot:top>
                <div class="d-flex justify-center py-1 body-1 font-weight-bold">
                  Sucursales
                </div>
                <v-divider></v-divider>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto my-2"
                  max-width="400"
                  type="info"
                  dense
                  text
                >
                  Habilitado para todas las sucursales
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" sm="8" md="6" class="pt-3 pb-0 pr-0 pl-md-3 pl-0">
            <v-data-table
              class="elevation-2"
              :sort-by="datos.opcion == 4 ? 'local_nombre' : 'pto_vta_nombre'"
              :headers="datos.opcion == 4 ?
                [{ text: 'Locales', value: 'local_nombre', align: 'center', sortable: false }] :
                [{ text: 'Locales', value: 'pto_vta_nombre', align: 'center', sortable: false }]
              "
              :items="locales"
              :loading="load"
              :items-per-page="-1"
              hide-default-header
              hide-default-footer
              dense
            >
              <template v-slot:top>
                <div class="d-flex justify-center py-1 body-1 font-weight-bold">
                  Locales
                </div>
                <v-divider></v-divider>
              </template>
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto my-2"
                  max-width="400"
                  type="info"
                  dense
                  text
                >
                  Habilitado para todos los locales
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="secondary"
          :disabled="load"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      load: false,
      locales: [],
      canales: [],
      sucursales: []
    }
  },
  props: {
    value: Boolean,
    datos: {
      type: Object,
      default: {
        opcion: null,
        id: null
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.load = true
        this.locales = []
        this.canales = []
        this.sucursales = []
        let api = ''
        let data = ''
        if (this.datos.opcion == 4) {
          api = 'get_detalle_promociones'
          data = 'promoDetalles'
        }
        else if (this.datos.opcion == 5) {
          api = 'get_detalle_financiaciones'
          data = 'detallesEspeciales'
        }
        if (api) {
          await this.$store.dispatch('financiaciones/' + api, {
            opcion: this.datos.opcion,
            id: this.datos.id
          })
            .then((res) => {
              this.locales = res[data].locales
              this.canales = res[data].canales
              this.sucursales = res[data].sucursales
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        }
        this.load = false
      }
    }
  }
}
</script>