<template>
  <div>
    <vue-flip :height="height" :width="width" active-click>
      <template v-slot:front>
        <v-card 
          :width="width" 
          :height="height" 
          elevation="4"
          class="overflow-y-auto"
          v-scroll.self="onScroll"
        >
          <v-card-title class="pb-0 text-center" style="font-size: 32px; word-break: normal;">
            <v-spacer></v-spacer>
            <!-- Tajeta y Cuota -->
            {{ tarjeta }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title style="font-size: 32px;" class="mt-0 pt-0 text-center" v-if="permiso == 1">
            <v-spacer></v-spacer>
            <!-- Porcentaje de la Promoción -->
            {{porcentaje}}%
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12">
                <!-- Nombre de Promoción -->
                <p class="text-center ma-0">{{ forma_pago_nombre }}</p>
                <!-- Vigencia -->
                <p class="text-center ma-0">{{ vigencia }}</p>
                <!-- Tarjeta -->
                <!-- <p class="text-center ma-0">{{ tarjeta }}</p> -->
                <!-- Rubros -->
                <p class="text-center ma-0">{{ cadena_rubros }}</p>
                <!-- Destinatarios -->
                <p class="text-center ma-0">{{ cadena_destinatarios }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:back>
        <v-card 
          :width="width" 
          :height="height" 
          elevation="4"
          class="overflow-y-auto"
          v-scroll.self="onScroll"
        >
          <v-card-title class="pb-0 text-center" style="font-size: 32px; word-break: normal;">
            <v-spacer></v-spacer>
            <!-- Tajeta y Cuota -->
            {{ tarjeta }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title style="font-size: 32px;" class="mt-0 pt-0 text-center" v-if="permiso == 1">
            <v-spacer></v-spacer>
            <!-- Porcentaje de la Promoción -->
            {{porcentaje}}%
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12">
                <!-- Categorias -->
                <p class="text-center ma-0">{{ cadena_categorias }}</p>
                <!-- Marcas -->
                <p class="text-center ma-0">{{ cadena_marcas }}</p>
                <!-- Articulos -->
                <p class="text-center ma-0">{{ cadena_articulos }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </vue-flip>
  </div>
</template>

<script>
import VueFlip from 'vue-flip'
export default {
  components: {
    'vue-flip': VueFlip
  },
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    height: {
      get () {
        return this.datos.height
      }
    },
    width: {
      get () {
        return this.datos.width
      }
    },
    porcentaje: {
      get () {
        return this.datos.porcentaje
      }
    },
    forma_pago_nombre: {
      get () {
        return this.datos.forma_pago_nombre
      }
    },
    vigencia: {
      get () {
        return `Vigente del ${this.datos.vigencia_desde}${(this.datos.vigencia_hasta == null || this.datos.vigencia_hasta == undefined || this.datos.vigencia_hasta.toString().length == 0) ? '' : ' al ' + this.datos.vigencia_hasta}`
      }
    },
    tarjeta: {
      get () {
        return `${this.datos.tarjeta_nombre != null && this.datos.tarjeta_nombre != undefined ? this.datos.tarjeta_nombre : this.datos.forma_pago_nombre} - ${this.datos.cuota_nombre != null && this.datos.cuota_nombre != undefined ? this.datos.cuota_nombre : ''}`
      }
    },
    cadena_rubros: {
      get () {
        return this.datos.cadena_rubros
      }
    },
    cadena_destinatarios: {
      get () {
        return this.datos.cadena_destinatarios
      }
    },
    cadena_categorias: {
      get () {
        return this.datos.cadena_categorias
      }
    },
    cadena_marcas: {
      get () {
        return this.datos.cadena_marcas
      }
    },
    cadena_articulos: {
      get () {
        return this.datos.cadena_articulos
      }
    },
    permiso: {
      get () {
        return this.datos.permiso
      }
    }
  },
  data() {
    return {
      scrollInvoked: 0
    }
  },
  methods: {
    onScroll(){
      this.scrollInvoked++
    },
  },
}
</script>

<style>

</style>