<template>
  <div>
    <vue-flip :height="height" :width="width" :active-click="false" :active-hover="false" v-model="activo">
      <!-- Frente -->
      <template v-slot:front>
        <v-card 
          :width="width" 
          :height="height" 
          elevation="4"
          class="overflow-y-auto imgFront"
          v-scroll.self="onScroll"
        >
          <v-card-title class="text-center pb-0" style="font-size: 34px">
            <v-spacer></v-spacer>
            {{ porcentaje }}%
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title class="text-center pt-0" style="font-size: 28px; word-break: normal;">
            <v-spacer></v-spacer>
            {{ promocion_nombre }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12">
                <!-- Vigencia -->
                <p class="text-center ma-0" style="word-break: normal;" v-if="fecha_desde != null && fecha_desde != undefined && fecha_desde.toString().length > 0 && fecha_hasta != null && fecha_hasta != undefined && fecha_hasta.toString().length > 0">Válida desde el {{ fecha_desde }} al {{ fecha_hasta }}.</p>
                <p class="text-center ma-0" style="word-break: normal;" v-if="fecha_desde != null && fecha_desde != undefined && fecha_desde.toString().length > 0 && (fecha_hasta == null || fecha_hasta == undefined || fecha_hasta.toString().length == 0)">Válida desde el {{ fecha_desde }}.</p>
                <p class="text-center ma-0" style="word-break: normal;" v-if="(fecha_desde == null || fecha_desde == undefined || fecha_desde.toString().length == 0) && fecha_hasta != null && fecha_hasta != undefined && fecha_hasta.toString().length > 0">Válida hasta el {{ fecha_hasta }}.</p>
                <!-- Dias -->
                <p class="text-center ma-0" style="word-break: normal;">{{ cadena_dias }}</p>
                <!-- Canales -->
                <p class="text-center ma-0" style="word-break: normal;" v-if="cadena_canales.toString().length > 0">Canales: {{ cadena_canales }}</p>
                <!-- Sucursales -->
                <p class="text-center ma-0" style="word-break: normal;" v-if="cadena_sucursales.toString().length > 0">Sucursales: {{ cadena_sucursales }}</p>
                <!-- Locales -->
                <p class="text-center ma-0" style="word-break: normal;" v-if="cadena_locales.toString().length > 0">Locales: {{ cadena_locales }}</p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn small color="success" @click="activo = !activo"><v-icon small class="ma-2">fas fa-plus</v-icon> Info</v-btn>
          </v-card-actions>
        </v-card>
      </template>
      <!-- Atrás -->
      <template v-slot:back>
        <v-card 
          :width="width" 
          :height="height" 
          elevation="4"
          class="overflow-y-auto"
          v-scroll.self="onScroll"
        >
          <v-card-title class="pb-0 pt-5">
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="2" class="pt-0 pb-0">

              </v-col>
              <v-col cols="8" align="center" class="pt-0 pb-0">
                <p style="font-size: 34px;" class="text-center">{{ porcentaje }}%</p>
              </v-col>
              <v-col cols="2" align="center" class="pt-0 pb-0">
                <v-btn icon small color="error" @click="activo = !activo" title="Volver"><v-icon small class="ma-2">fas fa-arrow-right</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title class="text-center pt-0 pb-0" style="font-size: 16px; word-break: normal; line-height: 22px;">
            <v-spacer></v-spacer>
            {{ promocion_nombre }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <!-- Titulo Aviso si no esta marcada como Categoria ni como articulos -->
            <p class="text-center ma-0" style="word-break: normal;" v-if="cadena_categorias.length == 0 && datos_articulos.length == 0">Aplica a las siguientes Categorias y/o Artículos: Todos.</p>
            <!-- Solo categorias -->
            <p class="ma-0 text-center" style="word-break: normal;" v-if="cadena_categorias.length > 0 && datos_articulos.length == 0">Aplica para las siguientes Categorias: </p>
            <!-- Solo Categorias -->
            <p class="ma-0" style="word-break: normal;" align="justify" v-if="cadena_categorias.length > 0 && datos_articulos.length == 0">{{ cadena_categorias }}</p>
            <!-- Solo Artículos -->
            <p class="ma-0 text-center" style="word-break: normal;" v-if="datos_articulos.length > 0">Aplica para los siguientes Artículos: </p>
            <!-- Solo Artículos -->
            <div v-if="datos_articulos.length > 0" class="ma-0">
              <p
                v-for="(art, index) in datos_articulos"
                :key="index"
                class="ma-0" 
                style="word-break: normal;" 
                align="justify"
              >
                * {{ art.articulo_codigo }} - {{ art.articulo_nombre }}
              </p>
            </div>
            <!-- Artículos Exceptuados -->
            <p v-if="datos_arts_exceptuados.length > 0" class="ma-0 text-center" style="word-break: normal;">Promoción no válida para los siguientes artículos: </p>
            <div v-if="datos_arts_exceptuados.length > 0">
              <p
                v-for="(art, index) in datos_arts_exceptuados"
                :key="index"
                class="ma-0" 
                style="word-break: normal;" 
                align="justify"
              >
                * {{ art.articulo_codigo }} - {{ art.articulo_nombre }}
              </p>
            </div>
          </v-card-text>
          <!-- <v-card-actions class="d-flex justify-center">
            <v-btn small color="error" @click="activo = !activo"><v-icon small class="ma-2">fas fa-arrow-left</v-icon> Volver</v-btn>
          </v-card-actions> -->
        </v-card>
      </template>
    </vue-flip>
  </div>
</template>

<script>
import VueFlip from 'vue-flip'
export default {
  components: {
    'vue-flip': VueFlip
  },
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    height: {
      get () {
        return this.datos.height
      }
    },
    width: {
      get () {
        return this.datos.width
      }
    },
    porcentaje: {
      get () {
        return this.datos.porcentaje
      }
    },
    promocion_nombre: {
      get () {
        return this.datos.promocion_nombre
      }
    },
    permiso: {
      get () {
        return this.datos.permiso
      }
    },
    cadena_dias: {
      get () {
        return this.datos.cadena_dias
      }
    },
    cadena_categorias: {
      get () {
        return this.datos.cadena_categorias
      }
    },
    cadena_articulos: {
      get () {
        return this.datos.cadena_articulos
      }
    },
    cadena_canales: {
      get () {
        return this.datos.cadena_canales
      }
    },
    cadena_sucursales: {
      get () {
        return this.datos.cadena_sucursales
      }
    },
    cadena_locales: {
      get () {
        return this.datos.cadena_locales
      }
    },
    datos_articulos: {
      get () {
        return this.datos.datos_articulos
      }
    },
    datos_arts_exceptuados: {
      get () {
        return this.datos.datos_arts_exceptuados
      }
    },
    fecha_desde: {
      get () {
        return this.datos.fecha_desde
      }
    },
    fecha_hasta: {
      get () {
        return this.datos.fecha_hasta
      }
    }
  },
  data() {
    return {
      scrollInvoked: 0,
      activo: false
    }
  },
  methods: {
    onScroll(){
      this.scrollInvoked++
    },
  }
}
</script>

<style scoped>
  .imgFront {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/celushop-5a30e.appspot.com/o/Jarvis2%2FTarjetas%20Rotativas%2FFONDOS-01.jpg?alt=media&token=e851ce86-4c5c-4f6f-9cab-f461356ab38d") !important;
    background-size:cover !important;
  }
</style>